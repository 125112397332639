import React, { useState } from 'react';
import { WineTrip, WineTripShell } from '../components/page-wrappers';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';

// tslint:disable no-default-export
export default () => {
  const [overylayOpen, setOverlayOpen] = useState(false);
  const [promoOverlayOpen, setPromoOverlayOpen] = useState(false);
  const [paymentOverlayOpen, setPaymentOverlayOpen] = useState(false);

  // useEffect(() => {
  //   const hasShownPromo = localStorage.getItem('hasShownPromo');
  //   const id = setTimeout(() => {
  //     if (!hasShownPromo) {
  //       setPromoOverlayOpen(true);
  //       localStorage.setItem('hasShownPromo', 'true');
  //     }
  //   }, 15000);

  //   return () => {
  //     clearTimeout(id);
  //   };
  // }, []);

  return (
    <>
      <Helmet title="Valle de Guadalupe Wine Tours">
        <link
          rel="canonical"
          href="https://www.valledeguadalupewinetours.com/valle-de-guadalupe-tours"
        />
        {process.env.NODE_ENV === 'production' ? (
          <script src={withPrefix('fullstory.js')} type="text/javascript" />
        ) : undefined}
      </Helmet>
      <WineTripShell
        overylayOpen={overylayOpen}
        setOverlayOpen={setOverlayOpen}
        promoOverlayOpen={promoOverlayOpen}
        paymentOverlayOpen={paymentOverlayOpen}
        setPromoOverlayOpen={setPromoOverlayOpen}
        setPaymentOverlayOpen={setPaymentOverlayOpen}
      >
        <WineTrip setOverlayOpen={setOverlayOpen} setPromoOverlayOpen={setPromoOverlayOpen} />
      </WineTripShell>
    </>
  );
};
